import React from "react";

const EventItem = ({ title, description, imageSrc }) => {
  return (
    <>
      <div>
        <div className="col-xl-12">
          <div className="event-item">
            <img srcSet={imageSrc} alt={title} rel="preload" />
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventItem;
