import React, { useState, useRef } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EventItem from "../element/eventsInfo";
import EventMobileItem from "../element/mobileInfo";
import MediaItem from "../element/mediaItem";
import MediaMobileItem from "../element/mediamobileItem";


// medias


function MediaAndPR() {
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [showMoreMedia, setShowMoreMedia] = useState(false);

  const initialMediaToShow = 6;

  const toggleEvents = () => {
    setShowAllEvents(!showAllEvents);
  };

  const sliderRef = useRef(null);

  const previousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const toggleShowMoreMedia = () => {
    setShowMoreMedia(!showMoreMedia);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
  };

  const eventsData = [
    {
      id: 1,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3263.webp",
    },
    {
      id: 2,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3264.png",
    },
    {
      id: 3,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3265.png",
    },
    {
      id: 4,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3266.png",
    },
    {
      id: 5,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3263.png",
    },
    {
      id: 6,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3264.png",
    },
  ];

  const mediaCoverage = [
    {
      id: 1,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3267.png",
    },
    {
      id: 2,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3268.png",
    },
    {
      id: 3,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3269.png",
    },
    {
      id: 4,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3270.png",
    },
    {
      id: 5,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3267.png",
    },
    {
      id: 6,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3268.png",
    },
    {
      id: 7,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3267.png",
    },
    {
      id: 8,
      title: "Lorem ipsum dolor sit ame consectur",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageSrc: "Rectangle 3268.png",
    },
  ];

  return (
    <>
      <Header />
      <Helmet>
        <title>
          Media & PR | Latest News and Press Releases | Bridge Health
        </title>
        <meta
          name="description"
          content="Stay updated with the latest news and press releases from Bridge Health. Explore our media and PR page for the most recent updates and exciting developments."
        />
      </Helmet>
      {/* <!-- Page Banner Section --> */}

      <section className="team-section padding doctoeback image-mobile-terms">
        {/* About Section Two */}
        <section className="container-fluid">
          <div className="row align-items-center auto-container reverseFlex">
            <div className="col-lg-6">
              <div className="desk-top">
                <div className="content-box marginTop40header">
                  <div className="sec-title text-center textleft">
                    <h2 className="textleft ">
                      <span className="headeingcolorblack">
                        WHERE CARE TAKES <br></br>THE{" "}
                      </span>

                      <span className="headeingcolorblue">CENTER STAGE</span>
                    </h2>
                    <h3 className="textleft ">
                      Discover the journey of Bridge Health, <br />
                      crafting a diverse array of exceptional care{" "}
                    </h3>
                  </div>
                </div>
              </div>
              {/* Mobile Code */}
              <div className="mob-mobile">
                <div className="content-box marginTop40header">
                  <div className="text-center textleft">
                    <h2 className="textleft fontSize50 mt-5 uni-affi">
                      <span className="headeingcolorblack">
                        WHERE CARE TAKES THE{" "}
                      </span>{" "}
                      <br></br>sit
                      <span className="headeingcolorblue"> CENTER STAGE</span>
                    </h2>
                    <h3 className="uni-media">
                      Discover the journey of Bridge Health, crafting a diverse
                      array of exceptional care{" "}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="imgright">
                <img
                  srcset={require("../../assets/images/shape/mediapr1.webp")}
                  alt=""
                  className="imgwidth wrap2-shi-img1 mobilehide"
                  rel="preload"
                />
                <img
                  srcSet={require("../../assets/images/shape/media-mob.webp")}
                  alt=""
                  className="imgwidth wrap2-shi-img1 desktophide"
                  rel="preload"
                />
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* Desktop code  */}
      <section className="media-events-bg desk-afflicate marginMedia">
        <div className="container-event-media">
          <h2 className="media-pr">EVENTS</h2>
          <section>
            <div className="row">
              {eventsData
                .slice(0, showAllEvents ? eventsData.length : 4)
                .map((event) => (
                  <div className="col-xl-3" key={event.id}>
                    <EventItem
                      key={event.id}
                      title={event.title}
                      description={event.description}
                      imageSrc={event.imageSrc}
                    />
                  </div>
                ))}
            </div>
            <div className="media-see-btn">
              <div className="btn-style-media">
                {showAllEvents ? (
                  <button onClick={toggleEvents}>SEE LESS</button>
                ) : (
                  <button onClick={toggleEvents}>SEE MORE</button>
                )}
              </div>
            </div>
          </section>
        </div>
      </section>

      {/* Mobile code  */}
      <section className="media-events-bg-mob mob-afflicate">
        <div className="container">
          <h2 className="media-pr-mob">Events</h2>
          <section>
            <div className="row">
              {eventsData
                .slice(0, showAllEvents ? eventsData.length : 4)
                .map((event) => (
                  <div className="col-6" key={event.id}>
                    <EventMobileItem
                      key={event.id}
                      title={event.title}
                      description={event.description}
                      imageSrc={event.imageSrc}
                    />
                  </div>
                ))}
            </div>

            <div className="media-see-btn">
              <div className="btn-style-media">
                {showAllEvents ? (
                  <button onClick={toggleEvents}>SEE LESS</button>
                ) : (
                  <button onClick={toggleEvents}>SEE MORE</button>
                )}
              </div>
            </div>
          </section>
        </div>
      </section>

      {/* Desktop code  */}
      <section className="desk-afflicate marginMedia">
        <div className="container-blog">
          <h2 className="media-titlw">MEDIA COVERAGE</h2>
          <section className="media-lower">
            <div className="row">
              <div className="col-xl-12">
                <Slider
                  ref={sliderRef}
                  {...settings}
                  className="theme_carousel"
                >
                  {mediaCoverage.map((event) => (
                    <div className="row" key={event.id}>
                      <MediaItem
                        key={event.id}
                        title={event.title}
                        description={event.description}
                        imageSrc={event.imageSrc}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>

            <div className="aff-slider-buttons">
              <div className="aff-space"></div>
              <div>
                <button
                  className="affiliate-prev"
                  onClick={previousSlide}
                ></button>
                <button className="affiliate-next" onClick={nextSlide}></button>
              </div>
            </div>
          </section>
        </div>
      </section>

      {/* Mobile code  */}
      <section className="mob-afflicate">
        <div className="container">
          <h2 className="media-titlw-mob">Media Coverage</h2>
          <section className="media-lower">
            <div className="row">
              <div className="col-lg-6">
                <div className="card-media-wrap">
                  {mediaCoverage
                    .slice(
                      0,
                      showMoreMedia ? mediaCoverage.length : initialMediaToShow
                    )
                    .map((event) => (
                      <div className="row" key={event.id}>
                        <MediaMobileItem
                          key={event.id}
                          title={event.title}
                          description={event.description}
                          imageSrc={event.imageSrc}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {mediaCoverage.length > initialMediaToShow && (
              <div className="btn-box text-center">
                <button
                  className="commonBtnforAll"
                  onClick={toggleShowMoreMedia}
                >
                  {showMoreMedia ? "SEE LESS" : "SEE MORE"}
                </button>
              </div>
            )}
          </section>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default MediaAndPR;