import React, { Component } from "react";

class Menu extends Component {
  render() {
    return (
      <>
        <div
          className="collapse navbar-collapse show clearfix "
          id="navbarSupportedContent"
        >
          <div className="menu-outer">
            <ul className="navigation clearfix">
              <li>
                <a
                  href="/personal-membership-program"
                  title="Personal Membership Program"
                >
                  Personal Membership Program
                </a>
              </li>

              <li>
                <a href="/corporate-membership-program">
                  Corporate Membership <br></br> Program
                </a>
              </li>

              <li>
                <a title="HPV" href="/hpv-vaccines">
                  HPV
                </a>
              </li>
              <li>
                <a title="Shingles Vaccination" href={"/shingles"}>
                  Shingles Vaccination
                </a>
              </li>
              {/* <li>
                <a title="Media" href="/media-and-pr">
                  Media
                </a>
              </li> */}
              <li>
                <a title="FAQ" href="/faq">
                  FAQ
                </a>
              </li>
              <li>
                <a title="Health Risk Assessment" href="/hra">
                  Health Risk Assessment
                </a>
              </li>

              <li>
                <a title="Diabetes Management" href="/diabetes">
                  Diabetes Management
                </a>
              </li>
              <li>
                <a title="Hypertension Management" href="/hyper-tension">
                  Hypertension Management
                </a>
              </li>
              <li>
                <a title="Weight Management" href="/manage-weight">
                  Weight Management
                </a>
              </li>
              <li>
                <a
                  title="Personal Health Plans"
                  href="/personal-membership-program"
                >
                  Personal Health Plans
                </a>
              </li>
              <li className="divider">
                <a href="/corporate-membership-program">Corporate Wellness</a>
              </li>
              <li>
                <a title="Corporate Wellness" href="/comprehensive-health">
                  Comprehensive Health Checks
                </a>
              </li>
              <li>
                <a title="Consultation" href="/doctor-consults">
                  Consultation
                </a>
              </li>
              <li className="divider">
                <a title="Psychology" href="/psychological-Health">
                  Psychology
                </a>
              </li>
              <li className="divider">
                <a title="Nutrition" href="/diet-and-nutrition">
                  Nutrition
                </a>
              </li>
              <li className="divider">
                <a title="Yoga" href="/yoga-Assessment">
                  Yoga
                </a>
              </li>
              <li className="divider">
                <a title="Fitness" href="/fitness-assessment">
                  Fitness
                </a>
              </li>
              <li className="divider">
                <a title="Physiotherapy" href="/physiotherapy">
                  Physiotherapy
                </a>
              </li>
              <li className="divider">
                <div title="Specialist Consults" style={{cursor:'pointer',fontSize:'15px',marginLeft:'32px',color:'white'}}>
                  Specialist Consults
                </div>
              </li>
              <li className="divider">
               
                <a title="Nursing" href="/nursing-at-home">
                  Nursing
                </a>
              </li>
              <li className="divider">
                <a title="Pharmacy" href="/pharmacy">
                  Pharmacy
                </a>
              </li>
              <li className="divider">
                <a title="Elder Care" href="/elderly-care">
                  Elder Care
                </a>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}
export default Menu;
